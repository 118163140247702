<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section class="row justify-end">
        <q-input
          outlined
          label="Cari Nama/NISN"
          v-model="searchTerm"
          style="max-width:300px"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section horizontal>
        <q-card-section
          class="column q-gutter-md q-pt-none"
          style="min-width:300px"
        >
          <q-select
            outlined
            stack-label
            label="Kelas"
            @input="getMapel(), (selected.mapel = null)"
            v-model="selected.kelas"
            :options="option.kelas"
          />
          <q-select
            stack-label
            label="Pilih Mapel"
            outlined
            v-model="selected.mapel"
            :options="option.mapel"
            :disable="selected.kelas == null"
          ></q-select>

          <q-select
            stack-label
            label="Pilih Kategori"
            outlined
            v-model="selected.kategori"
            :options="option.kategori"
          ></q-select>
          <q-btn
            outline
            color="primary"
            label="TAMPILKAN"
            @click="tampilkan"
            :disable="
              selected.kelas == null ||
                selected.kategori == null ||
                selected.mapel == null
            "
          ></q-btn>
        </q-card-section>
        <template>
          <q-markup-table
            v-if="!showTable"
            dense
            separator="horizontal"
            class="stickyTable bg-grey-4"
            style="width:724px"
          >
            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr>
                <td class="text-center">
                  Isi pilihan lalu tekan tombol TAMPILKAN
                </td>
              </tr>
            </tbody>
          </q-markup-table>
          <div v-else>
            <TableTotal
              ref="tableTotal"
              v-if="selected.kategori == 'TOTAL'"
              :data="selected"
              :searchTerm="searchTerm"
            ></TableTotal>

            <TableDetail
              ref="tableDetail"
              v-if="selected.kategori != 'TOTAL'"
              :data="selected"
              :searchTerm="searchTerm"
            ></TableDetail>
          </div>
        </template>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import TableDetail from "@/views/HasilRekapNilai/TableDetail";
import TableTotal from "@/views/HasilRekapNilai/TableTotal";
export default {
  components: {
    TableDetail,
    TableTotal,
  },
  data() {
    return {
      searchTerm: "",
      option: {
        kelas: [],
        mapel: [],
        kategori: ["TUGAS", "UH", "KETERAMPILAN", "TOTAL"],
      },
      selected: {
        kelas: null,
        mapel: null,
        kategori: null,
      },
      showTable: false,
    };
  },
  mounted() {
    this.getKelas();
  },
  methods: {
    tampilkan() {
      this.showTable = true;
      if (this.selected.kategori == "TOTAL") {
        try {
          this.$nextTick(() => {
            this.$refs.tableTotal.tampilkan();
          });
        } catch (e) {
          console.log(e);
        }
      } else if (this.selected.kategori != "TOTAL") {
        try {
          this.$nextTick(() => {
            this.$refs.tableDetail.tampilkan();
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.selected.kelas = null;
      this.option.kelas = [];
      await this.$http
        .get(`/hasil/ranking/getkelas/${localStorage.getItem("jenjang")}`)
        .then((resp) => {
          this.option.kelas = resp.data;
        });
      this.$q.loading.hide();
    },
    async getMapel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(`/hasil/rekap_nilai/getmapelinkelas/${this.selected.kelas.value}`)
        .then((resp) => {
          this.option.mapel = resp.data;
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
