<template>
  <q-markup-table
    dense
    separator="horizontal"
    class="stickyHeaderColumn bg-grey-4"
    style="width:724px;"
  >
    <thead>
      <tr>
        <th>Nama</th>
        <th v-for="(el, i) in listKD" :key="i">
          {{ el.label }}
          <q-btn
            flat
            color="white"
            size="xs"
            icon="workspaces"
            @click="showSummary(i)"
          ></q-btn>
        </th>
        <th>rerata</th>
      </tr>
    </thead>
    <tbody class="bg-white">
      <tr
        v-for="el in siswa"
        :key="el.id"
        :class="{ 'bg-red-2': el.rerata < kkm }"
      >
        <td>{{ el.nama }}</td>
        <td
          v-for="el2 in el.list_nilai"
          :key="el2.id"
          :class="{ 'text-negative': el2 < kkm }"
        >
          {{ el2 }}
        </td>
        <td>{{ el.rerata }}</td>
      </tr>
    </tbody>
    <q-dialog v-model="dialogSummary">
      <q-card>
        <q-list>
          <q-item>
            <q-item-section>Tidak Tuntas : </q-item-section>
            <q-item-section side>{{ summary.remed }} </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>Nilai Minimal : </q-item-section>
            <q-item-section side>{{ summary.min }} </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>Nilai Maksimal : </q-item-section>
            <q-item-section side>{{ summary.max }} </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>Daya Serap : </q-item-section>
            <q-item-section side>{{ summary.daya_serap }}%</q-item-section>
          </q-item>
          <q-item>
            <q-item-section>Capaian Daya Serap : </q-item-section>
            <q-item-section side>{{
              summary.capaian_daya_serap
            }}</q-item-section>
          </q-item>
        </q-list>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="close"
            @click="hideSummary"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-markup-table>
</template>
<script>
export default {
  props: ["data", "searchTerm"],
  data() {
    return {
      dialogSummary: false,
      summary: {
        remed: 0,
        min: 100,
        max: 0,
        daya_serap: 0,
        capaian_daya_serap: "",
      },
      listKD: [],
      siswa: [],
      kkm: 0,
      is_mapel_ready: true,
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  async mounted() {
    await this.tampilkan();
  },
  methods: {
    hideSummary() {
      this.summary = {
        remed: 0,
        min: 100,
        max: 0,
        daya_serap: 0,
        capaian_daya_serap: "",
      };
      this.dialogSummary = false;
    },
    showSummary(idx) {
      let tempSummary = {
        remed: 0,
        min: 100,
        max: 0,
        daya_serap: 0,
        capaian_daya_serap: "",
      };
      let jumlah = 0;
      let banyak = 0;
      for (let item of this.siswa) {
        //get jumlah remed & daya serap
        if (item.list_nilai[idx] != "-") {
          jumlah = jumlah + parseFloat(item.list_nilai[idx]);
          banyak++;
          if (item.list_nilai[idx] < this.kkm) {
            tempSummary.remed++;
          }
        }

        //get nilai terbesar
        if (parseFloat(item.list_nilai[idx]) > parseFloat(tempSummary.max)) {
          tempSummary.max = item.list_nilai[idx];
        }

        //get nilai terkecil
        if (parseFloat(item.list_nilai[idx]) < parseFloat(tempSummary.min)) {
          tempSummary.min = item.list_nilai[idx];
        }
      }
      if (banyak > 0) {
        tempSummary.daya_serap = parseFloat(jumlah / banyak).toFixed(2);
        if (tempSummary.daya_serap < 40) {
          tempSummary.capaian_daya_serap = "Sangat Rendah";
        } else if (tempSummary.daya_serap < 60) {
          tempSummary.capaian_daya_serap = "Rendah";
        } else if (tempSummary.daya_serap < 75) {
          tempSummary.capaian_daya_serap = "Sedang";
        } else if (tempSummary.daya_serap < 85) {
          tempSummary.capaian_daya_serap = "Tinggi";
        } else if (tempSummary.daya_serap <= 100) {
          tempSummary.capaian_daya_serap = "Sangat Tinggi";
        }
        this.summary = tempSummary;
      } else {
        this.summary = {
          remed: "-",
          min: "-",
          max: "-",
          daya_serap: "-",
          capaian_daya_serap: "-",
        };
      }
      this.dialogSummary = true;
    },
    async tampilkan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.getKKM();
      await this.getListKD();
      await this.getSiswaList();
      await this.getIDNilaiAkademik();
      await this.getNilai();
      this.$q.loading.hide();
    },
    async getKKM() {
      await this.$http
        .get(`penilaian/input_nilai/getkkm/${this.data.mapel.value}`)
        .then((resp) => {
          this.kkm = parseFloat(resp.data.kkm);
        });
    },
    async getListKD() {
      await this.$http
        .get(
          `/penilaian/input_nilai/getkd/${this.data.mapel.value}/${this.data.kategori}`
        )
        .then((resp) => {
          this.listKD = resp.data;
        });
    },
    async getSiswaList() {
      await this.$http
        .get(
          `/penilaian/catatan/getsiswa/${
            this.data.kelas.value
          }/${localStorage.getItem("id_tahun_ajaran")}`
        )
        .then((resp) => {
          this.siswa = resp.data;
        });
    },
    async getIDNilaiAkademik() {
      await this.$http
        .post(
          `/penilaian/input_nilai/getid_nilai/${
            this.data.mapel.id_guru_mapel
          }/${localStorage.getItem("id_tahun_ajaran")}`,
          this.siswa
        )
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          if (resp.data[0] == null) {
            this.is_mapel_ready = false;
          }
          for (let i in resp.data) {
            cpy[i].id_nilai_akademik = resp.data[i];
          }
          this.siswa = cpy;
        });
    },
    async getNilai() {
      if (!this.is_mapel_ready) return;
      await this.$http
        .post(`/hasil/rekap_nilai/getnilai/${this.data.kategori}`, this.siswa)
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          for (let i in resp.data) {
            // loop siswa
            // if (resp.data[i].length != this.listKD.length) {
            //   for (let j = resp.data[i].length; j < this.listKD.length; j++) {
            //     resp.data[i].push("-");
            //   }
            // }
            // cpy[i].list_nilai = resp.data[i];
            cpy[i].list_nilai = [];
            for (let j in this.listKD) {
              let match = resp.data[i].find((val) => {
                return val.id_kd == this.listKD[j].value;
              });
              if (match) {
                cpy[i].list_nilai.push(match.rerata);
              } else {
                cpy[i].list_nilai.push("-");
              }
            }
            let sum = 0;
            let c = 0;
            for (let j of resp.data[i]) {
              // loop nilai
              if (j != "-") {
                sum = sum + parseFloat(j.rerata);
                c = c + 1;
              }
            }
            if (c == 0) {
              cpy[i].rerata = "-";
            } else {
              cpy[i].rerata = sum / c;
            }
          }
          this.siswa = cpy;
        });
    },
  },
};
</script>
