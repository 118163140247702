<template>
  <q-markup-table
    dense
    separator="horizontal"
    class="stickyHeaderColumn bg-grey-4"
    style="width:724px"
  >
    <thead>
      <tr>
        <th rowspan="2">Nama</th>
        <th colspan="5">Pengetahuan</th>
        <th colspan="4">Nilai Akhir</th>
        <th colspan="4">Sikap</th>
        <th colspan="2">KD Menonjol</th>
      </tr>
      <tr class="stickyRow">
        <th class="bg-light-green-3">
          Ulangan Harian<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('UH')"
          ></q-btn>
        </th>
        <th class="bg-light-green-3">
          Rerata Tugas<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('TUGAS')"
          ></q-btn>
        </th>
        <th class="bg-light-green-3">
          Hasil UH & Tugas (50%)<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('UH_TUGAS')"
          ></q-btn>
        </th>
        <th class="bg-light-green-3">
          PTS (30%)<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('PTS')"
          ></q-btn>
        </th>
        <th class="bg-light-green-3">
          UAS (20%)<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('UAS')"
          ></q-btn>
        </th>
        <th class="bg-light-green-3">
          Nilai Pengetahuan<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('PENGETAHUAN')"
          ></q-btn>
        </th>
        <th class="bg-light-green-3">
          Predikat
        </th>
        <th class="bg-light-green-5">
          Nilai Keterampilan<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('KETERAMPILAN')"
          ></q-btn>
        </th>
        <th class="bg-light-green-5">
          Predikat
        </th>
        <th class="bg-cyan-12">
          Kehadiran<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('KEHADIRAN')"
          ></q-btn>
        </th>
        <th class="bg-cyan-12">
          Respon<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('RESPON')"
          ></q-btn>
        </th>
        <th class="bg-cyan-12">
          Adab<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('ADAB')"
          ></q-btn>
        </th>
        <th class="bg-cyan-12">
          Rerata<q-btn
            flat
            color="primary"
            size="xs"
            icon="workspaces"
            @click="showSummary('SIKAP')"
          ></q-btn>
        </th>
        <th class="bg-white">
          Pengetahuan
          <q-btn
            flat
            color="primary"
            size="xs"
            icon="visibility"
            @click="kd_pengetahuan = true"
          ></q-btn>
        </th>
        <th class="bg-white">
          Keterampilan
          <q-btn
            flat
            color="primary"
            size="xs"
            icon="visibility"
            @click="kd_keterampilan = true"
          ></q-btn>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white">
      <tr v-for="(el, i) in siswa" :key="i">
        <td id="nama">{{ el.nama }}</td>
        <td
          class="bg-light-green-3"
          :class="{ 'text-negative': el.rerata_uh < kkm }"
          id="UH"
        >
          {{ el.rerata_uh }}
        </td>
        <td
          class="bg-light-green-3"
          :class="{ 'text-negative': el.rerata_tugas < kkm }"
          id="Tugas"
        >
          {{ el.rerata_tugas }}
        </td>
        <td
          class="bg-light-green-3"
          :class="{ 'text-negative': el.rerata_both < kkm }"
          id="Rerata uh tugas"
        >
          {{ el.rerata_both }}
        </td>
        <td
          class="bg-light-green-3"
          :class="{ 'text-negative': el.pts < kkm }"
          id="pts"
        >
          {{ el.pts }}
        </td>
        <td
          class="bg-light-green-3"
          :class="{ 'text-negative': el.uas < kkm }"
          id="uas"
        >
          {{ el.uas }}
        </td>
        <td
          class="bg-light-green-3"
          :class="{ 'text-negative': el.total < kkm }"
          id="Nilai Pengetahuan"
        >
          {{ el.total }}
        </td>
        <td id="predikat_pengetahuan">{{ el.predikat_pengetahuan }}</td>
        <td class="bg-light-green-5" id="nilai keterampilan">
          {{ el.rerata_keterampilan }}
        </td>
        <td id="predikat_keterampilan">{{ el.predikat_keterampilan }}</td>
        <td
          class="bg-cyan-12"
          :class="{ 'text-negative': el.kehadiran < kkm }"
          id="kehadiran"
        >
          {{ el.kehadiran }}
        </td>
        <td
          class="bg-cyan-12"
          :class="{ 'text-negative': el.respon < kkm }"
          id="respon"
        >
          {{ el.respon }}
        </td>
        <td
          class="bg-cyan-12"
          :class="{ 'text-negative': el.adab < kkm }"
          id="adab"
        >
          {{ el.adab }}
        </td>
        <td
          class="bg-cyan-12"
          :class="{ 'text-negative': el.total_sikap < kkm }"
          id="rerata_sikap"
        >
          {{ el.total_sikap }}
        </td>
        <td>{{ el.kd_menonjol_pengetahuan }}</td>
        <td>{{ el.kd_menonjol_keterampilan }}</td>
      </tr>
    </tbody>
    <q-dialog v-model="kd_pengetahuan">
      <q-card>
        <q-card-section>
          <a> <strong>Kompetensi Dasar Pengetahuan</strong></a>
        </q-card-section>

        <q-markup-table class="stickyTable" separator="cell">
          <thead>
            <tr>
              <th>Nomor</th>
              <th>Nama</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, i) in listKDpengetahuan" :key="i">
              <td>{{ value.nomor }}</td>
              <td>{{ value.label }}</td>
            </tr>
          </tbody>
        </q-markup-table>

        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="kd_keterampilan">
      <q-card>
        <q-card-section>
          <a> <strong>Kompetensi Dasar Keterampilan</strong></a>
        </q-card-section>
        <q-markup-table class="stickyTable" separator="cell">
          <thead>
            <tr>
              <th>Nomor</th>
              <th>Nama</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, i) in listKDketerampilan" :key="i">
              <td>{{ value.nomor }}</td>
              <td>{{ value.label }}</td>
            </tr>
          </tbody>
        </q-markup-table>
        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="dialogSummary">
      <q-card>
        <q-list>
          <q-item>
            <q-item-section>Tidak Tuntas : </q-item-section>
            <q-item-section side>{{ summary.remed }} </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>Nilai Minimal : </q-item-section>
            <q-item-section side>{{ summary.min }} </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>Nilai Maksimal : </q-item-section>
            <q-item-section side>{{ summary.max }} </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>Daya Serap : </q-item-section>
            <q-item-section side>{{ summary.daya_serap }}%</q-item-section>
          </q-item>
          <q-item>
            <q-item-section>Capaian Daya Serap : </q-item-section>
            <q-item-section side>{{
              summary.capaian_daya_serap
            }}</q-item-section>
          </q-item>
        </q-list>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="close"
            @click="hideSummary"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-markup-table>
</template>
<script>
export default {
  props: ["data", "searchTerm"],
  data() {
    return {
      dialogSummary: false,
      summary: {
        remed: 0,
        min: 100,
        max: 0,
        daya_serap: 0,
        capaian_daya_serap: "",
      },
      kd_pengetahuan: false,
      kd_keterampilan: false,

      listKDpengetahuan: [],
      listKDketerampilan: [],
      siswa: [],
      predikat: {},
      kkm: 0,
      is_mapel_ready: true,
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  async mounted() {
    await this.tampilkan();
  },
  methods: {
    hideSummary() {
      this.summary = {
        remed: 0,
        min: 100,
        max: 0,
        daya_serap: 0,
        capaian_daya_serap: "",
      };
      this.dialogSummary = false;
    },
    //zz adalah kategori yg diminta. pake variabel zz karena agar mudah saat ngoding. jangan lupa diganti
    showSummary(zz) {
      let tempSummary = JSON.parse(JSON.stringify(this.summary));
      let jumlah = 0;
      let banyak = 0;

      for (let item of this.siswa) {
        let patokan = 0;
        //get nilai
        if (zz == "UH") {
          patokan = item.rerata_uh;
        } else if (zz == "TUGAS") {
          patokan = item.rerata_tugas;
        } else if (zz == "UH_TUGAS") {
          patokan = item.rerata_both;
        } else if (zz == "PTS") {
          patokan = item.pts;
        } else if (zz == "UAS") {
          patokan = item.uas;
        } else if (zz == "PENGETAHUAN") {
          patokan = item.total;
        } else if (zz == "KETERAMPILAN") {
          patokan = item.rerata_keterampilan;
        } else if (zz == "KEHADIRAN") {
          patokan = item.kehadiran;
        } else if (zz == "RESPON") {
          patokan = item.respon;
        } else if (zz == "ADAB") {
          patokan = item.adab;
        } else if (zz == "SIKAP") {
          patokan = item.total_sikap;
        }

        //get jumlah remed & daya serap
        if (patokan != "-") {
          jumlah = jumlah + parseFloat(patokan);
          banyak++;
          if (patokan < this.kkm) {
            tempSummary.remed++;
          }
        }

        //get nilai terbesar
        if (parseFloat(patokan) > parseFloat(tempSummary.max)) {
          tempSummary.max = patokan;
        }

        //get nilai terkecil
        if (parseFloat(patokan) < parseFloat(tempSummary.min)) {
          tempSummary.min = patokan;
        }
      }
      tempSummary.daya_serap = parseFloat(jumlah / banyak).toFixed(2);
      if (tempSummary.daya_serap < 40) {
        tempSummary.capaian_daya_serap = "Sangat Rendah";
      } else if (tempSummary.daya_serap < 60) {
        tempSummary.capaian_daya_serap = "Rendah";
      } else if (tempSummary.daya_serap < 75) {
        tempSummary.capaian_daya_serap = "Sedang";
      } else if (tempSummary.daya_serap < 85) {
        tempSummary.capaian_daya_serap = "Tinggi";
      } else if (tempSummary.daya_serap <= 100) {
        tempSummary.capaian_daya_serap = "Sangat Tinggi";
      }
      this.summary = tempSummary;
      this.dialogSummary = true;
    },
    async tampilkan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.getKKM();
      await this.getPredikat();
      await this.getListKDpengetahuan();
      await this.getListKDketerampilan();
      await this.getSiswaList();
      await this.getIDNilaiAkademik();
      await this.getNilai();
      this.$q.loading.hide();
    },
    async getKKM() {
      await this.$http
        .get(`penilaian/input_nilai/getkkm/${this.data.mapel.value}`)
        .then((resp) => {
          this.kkm = parseFloat(resp.data.kkm);
        });
    },
    async getListKDpengetahuan() {
      await this.$http
        .get(
          `/penilaian/input_nilai/getkd/${this.data.mapel.value}/PENGETAHUAN`
        )
        .then((resp) => {
          this.listKDpengetahuan = resp.data;
        });
    },
    async getListKDketerampilan() {
      await this.$http
        .get(
          `/penilaian/input_nilai/getkd/${this.data.mapel.value}/KETERAMPILAN`
        )
        .then((resp) => {
          this.listKDketerampilan = resp.data;
        });
    },
    async getPredikat() {
      //get predikat
      await this.$http
        .get(`/hasil/rekap_nilai/getpredikat/${this.data.mapel.value}`)
        .then((resp) => {
          this.predikat = resp.data;
        });
    },
    async getSiswaList() {
      await this.$http
        .get(
          `/penilaian/catatan/getsiswa/${
            this.data.kelas.value
          }/${localStorage.getItem("id_tahun_ajaran")}`
        )
        .then((resp) => {
          this.siswa = resp.data;
        });
    },
    async getIDNilaiAkademik() {
      await this.$http
        .post(
          `/penilaian/input_nilai/getid_nilai/${
            this.data.mapel.id_guru_mapel
          }/${localStorage.getItem("id_tahun_ajaran")}`,
          this.siswa
        )
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          if (resp.data[0] == null) {
            this.is_mapel_ready = false;
          }
          for (let i in resp.data) {
            cpy[i].id_nilai_akademik = resp.data[i];
          }
          this.siswa = cpy;
        });
    },
    async getNilai() {
      if (!this.is_mapel_ready) return;

      //get rerata tugas
      console.log(this.siswa);
      await this.$http
        .post(`/hasil/rekap_nilai/getnilaitotaltugas/`, this.siswa)
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          for (let i in resp.data) {
            // loop siswa
            if (resp.data[i].length == 0) {
              resp.data[i].push("-");
            }
            cpy[i].rerata_tugas = parseFloat(resp.data[i]).toFixed(2);
          }
          this.siswa = cpy;
        });

      //get rerata UH
      await this.$http
        .post(`/hasil/rekap_nilai/getnilai/UH`, this.siswa)
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          for (let i in resp.data) {
            // loop siswa
            if (resp.data[i].length != this.listKDpengetahuan.length) {
              for (
                let j = resp.data[i].length;
                j < this.listKDpengetahuan.length;
                j++
              ) {
                resp.data[i].push("-");
              }
            }
            cpy[i].list_uh = resp.data[i];
            let sum = 0;
            let c = 0;
            for (let j of resp.data[i]) {
              // loop nilai
              if (j != "-") {
                sum = sum + parseFloat(j);
                c = c + 1;
              }
            }
            if (c == 0) {
              cpy[i].rerata_uh = "-";
            } else {
              cpy[i].rerata_uh = sum / c;
            }
          }

          this.siswa = cpy;
        });

      await this.getUHTugas();

      //get uts uas sikap
      await this.$http
        .post(`/hasil/rekap_nilai/getutsuassikap`, this.siswa)
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          for (let i in resp.data) {
            cpy[i].pts = resp.data[i].pts;
            cpy[i].uas = resp.data[i].uas;
            cpy[i].respon = resp.data[i].respon;
            cpy[i].adab = resp.data[i].adab;
          }
          this.siswa = cpy;
        });

      await this.gettotal();

      //get rerata Keterampilan
      await this.$http
        .post(`/hasil/rekap_nilai/getnilai/KETERAMPILAN`, this.siswa)
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          for (let i in resp.data) {
            // loop siswa
            if (resp.data[i].length != this.listKDketerampilan.length) {
              for (
                let j = resp.data[i].length;
                j < this.listKDketerampilan.length;
                j++
              ) {
                resp.data[i].push("-");
              }
            }
            cpy[i].list_keterampilan = resp.data[i];
            let sum = 0;
            let c = 0;
            for (let j of resp.data[i]) {
              // loop nilai
              if (j != "-") {
                sum = sum + parseFloat(j);
                c = c + 1;
              }
            }
            if (c == 0) {
              cpy[i].rerata_keterampilan = "-";
            } else {
              cpy[i].rerata_keterampilan = sum / c;
            }
          }

          this.siswa = cpy;
        });

      //get rerata Kehadiran
      await this.$http
        .post(
          `/hasil/rekap_nilai/getkehadiran/${
            this.data.mapel.value
          }/${localStorage.getItem("id_tahun_ajaran")}`,
          this.siswa
        )
        .then((resp) => {
          let cpy = JSON.parse(JSON.stringify(this.siswa));
          for (let i in resp.data) {
            // loop siswa
            let semua = parseFloat(resp.data[i].semua);
            let hadir = parseFloat(resp.data[i].hadir);
            cpy[i].kehadiran = parseFloat(
              100 * parseFloat(hadir / semua)
            ).toFixed(2);
          }
          this.siswa = cpy;
        });

      await this.getSikap();

      await this.inputPredikat();

      await this.getKDmenonjol();
    },
    getKDmenonjol() {
      let cpy = JSON.parse(JSON.stringify(this.siswa));
      for (let siswa of cpy) {
        let top_pengetahuan = "";
        let value_top_pengetahuan = 0;
        for (let idx in siswa.list_uh) {
          if (
            siswa.list_uh[idx] != "-" &&
            parseFloat(siswa.list_uh[idx]) > value_top_pengetahuan
          ) {
            top_pengetahuan = this.listKDpengetahuan[idx].nomor;
            value_top_pengetahuan = parseFloat(siswa.list_uh[idx]);
          }
        }

        let top_keterampilan = "";
        let value_top_keterampilan = 0;
        for (let idx in siswa.list_keterampilan) {
          if (
            siswa.list_keterampilan[idx] != "-" &&
            parseFloat(siswa.list_keterampilan[idx]) > value_top_keterampilan
          ) {
            top_keterampilan = this.listKDketerampilan[idx].nomor;
            value_top_keterampilan = parseFloat(siswa.list_keterampilan[idx]);
          }
        }
        siswa.kd_menonjol_pengetahuan = top_pengetahuan;
        siswa.kd_menonjol_keterampilan = top_keterampilan;
      }
      this.siswa = cpy;
    },
    inputPredikat() {
      let cpy = JSON.parse(JSON.stringify(this.siswa));
      for (let i of cpy) {
        if (i.total >= this.predikat.A) {
          i.predikat_pengetahuan = "A";
        } else if (i.total < this.predikat.A && i.total > this.predikat.B) {
          i.predikat_pengetahuan = "B";
        } else if (i.total < this.predikat.B && i.total > this.predikat.C) {
          i.predikat_pengetahuan = "C";
        } else if (i.total < this.predikat.C && i.total > this.predikat.D) {
          i.predikat_pengetahuan = "D";
        }

        if (i.rerata_keterampilan >= this.predikat.A) {
          i.predikat_keterampilan = "A";
        } else if (
          i.rerata_keterampilan < this.predikat.A &&
          i.rerata_keterampilan > this.predikat.B
        ) {
          i.predikat_keterampilan = "B";
        } else if (
          i.rerata_keterampilan < this.predikat.B &&
          i.rerata_keterampilan > this.predikat.C
        ) {
          i.predikat_keterampilan = "C";
        } else if (
          i.rerata_keterampilan < this.predikat.C &&
          i.rerata_keterampilan > this.predikat.D
        ) {
          i.predikat_keterampilan = "D";
        }
      }
      this.siswa = cpy;
    },
    getSikap() {
      let cpy = JSON.parse(JSON.stringify(this.siswa));
      for (let i of cpy) {
        i.total_sikap = parseFloat(
          (parseFloat(i.adab) +
            parseFloat(i.kehadiran) +
            parseFloat(i.respon)) /
            3
        ).toFixed(2);
      }
      this.siswa = cpy;
    },
    gettotal() {
      let cpy = JSON.parse(JSON.stringify(this.siswa));
      for (let i of cpy) {
        i.total = parseFloat(
          (i.rerata_both * 50) / 100 + (i.pts * 30) / 100 + (i.uas * 20) / 100
        ).toFixed(2);
      }
      this.siswa = cpy;
    },
    getUHTugas() {
      //get rerata uh & tugas
      let cpy = JSON.parse(JSON.stringify(this.siswa));
      for (let i of cpy) {
        let sum = 0;
        let count = 1;
        for (let j of i.list_uh) {
          if (j != "-") {
            sum = sum + parseFloat(j);
            count = count + 1;
          }
        }
        sum = sum + parseFloat(i.rerata_tugas);
        i.rerata_both = parseFloat(sum / count).toFixed(2);
      }
      this.siswa = cpy;
    },
  },
};
</script>
